import React from 'react';
import styled, { css } from 'styled-components'
import CTAButton from './cta-button'
import { maxDevice } from "media/styles/mixins/breakpoints";


interface OwnProps {
    isLoading: boolean;
    onRegister: () => void;
    isRegistered: boolean;
    isRegistrationClosed: boolean;
    title: string;
    date: string;
    altDate?: string;
    eventLocation?: string;
    registerBtnText?: string;
    className?: string;
    showLimitedButton?: boolean;
    description?: string;
    darkBackgroundMode?: boolean;
    altTitle?: string;
    altClassName?: string;
}

export default function BannerNotRegistered({
    isLoading = false,
    onRegister,
    isRegistered,
    isRegistrationClosed = false,
    title,
    date,
    altDate = null,
    eventLocation,
    registerBtnText,
    className,
    showLimitedButton = true,
    description,
    darkBackgroundMode = false,
    altTitle = null,
    altClassName = null
}: OwnProps) {
    return (
        <Banner className={className} blackIcons={darkBackgroundMode} >
            <div className="title">
                <p>
                    <span>TransferRoom Summit <b>İ</b>stanbul</span>
                </p>
            </div>
            <div className="description">
                {description || 'Exclusively for decision makers and Trusted Agents. By Invitation Only.'}
            </div>
            <div className='event-label'>
                <label>
                    <div className='label-item'>
                        <div>
                            <span>İ</span>stanbul
                        </div>
                    </div>
                    <div className='label-item'>
                        <div>
                            Nov 11 + 12
                        </div>
                    </div>
                </label>
                <label>
                    <div className='label-item'>
                        <div>
                            Online
                        </div>
                    </div>
                    <div className='label-item'>
                        <div>
                            Nov 14
                        </div>
                    </div>
                </label>
            </div>
            <CTAButton
                isLoading={isLoading}
                isRegistrationNotYetOpened={false}
                isRegisrtarionClosed={isRegistrationClosed}
                isRegistered={isRegistered}
                onRegister={onRegister}
                registerBtnText={registerBtnText}
                isGreenMode={darkBackgroundMode}
            />
        </Banner>
    )
}

const Banner = styled.section`
    color: white;
    height: auto;
    min-height: 330px;
    text-align: center;
    background-color: #0E854C;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center 100%;
    padding: 51px 20px 60px;
    
    @media (max-width: 767px) {
        padding: 40px 20px;
    }

    .title {
        margin-bottom: 12px;
        
        p {
            &>span {
                color: #FFF;
                text-align: center;
                font-size: 45px;
                font-weight: 700;
                line-height: normal;

                @media ${maxDevice.tabletM} {
                    font-size: 35px;
                }

                @media ${maxDevice.tabletS} {
                    text-shadow: 0px 4px 16px rgba(19, 26, 54, 0.50);
                    font-size: 28px;
                }

                &>b {
                    font-family: Inter;
                    font-weight: 600;
                }
            }
        }
    }

    .description {
        margin-bottom: 25px;
        color:#ffffff;
        font-size: 18px;
        font-weight: 400;
        line-height: normal;

        @media ${maxDevice.tabletM} {
            font-size: 16px;
        }

        @media ${maxDevice.tabletS} {
                font-size: 14px;
        }
    }

    .event-label {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 12px;
        margin-bottom: 30px;
        
        @media ${maxDevice.tabletS} {
            flex-direction: column;
            align-items: center;
            margin-bottom: 23px;
            gap: 10px;
        }

        &>label {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            margin: 0;
            border-radius: 69.067px;
            border: 1.233px solid #FFF;
            background: rgba(255, 255, 255, 0.10);

            @media ${maxDevice.tabletS} {
                border-radius: 56px;
                border: 1px solid #FFF;
            }

            .label-item {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 37px;
                
                @media ${maxDevice.tabletS} {
                    height: 30px;
                }
                
                & div {
                    color: #FFF;
                    font-size: 18.5px;
                    font-weight: 700;
                    line-height: normal;
                    padding: 0 12px;

                    @media ${maxDevice.tabletS} {
                        font-size: 15px;
                    }

                    & span {
                        font-family: Inter;
                        font-weight: 600;
                    }
                }

                &:first-child {
                    border-right: 1.233px solid #FFF;
					border-radius: 69.067px 0px 0px 69.067px;
                    background: rgba(255, 255, 255, 0.25);
                }
            }
                
            @media ${maxDevice.tabletS} {
                font-size: 17px;
                max-width: fit-content;
            }
        }
    }

    &> button {
        background: #FFF;
        color: #131A36;
        font-size: 16px;
        font-weight: 700;
        line-height: 20px;
        letter-spacing: -0.16px;
        min-height: 50px;

        @media ${maxDevice.tabletS} {
            min-height: 48px;
            font-size: 14px;
            letter-spacing: -0.14px;
        }

        &:hover {
            background: #FFF;
            opacity: 0.9;
        }
    }
`