import React from 'react'
import styled, { css } from 'styled-components'
import Button from 'components/Buttons/Button/Button'

interface OwnProps {
    isRegistrationNotYetOpened: boolean,
    isRegisrtarionClosed: boolean,
    isLoading: boolean,
    isRegistered: boolean,
    onRegister: () => void,
    registerBtnText?: string
    isGreenMode?: boolean;
}

export default function CTAButton({ 
    isRegistrationNotYetOpened, 
    isRegisrtarionClosed, 
    isLoading, 
    isRegistered, 
    onRegister, 
    registerBtnText = "REGISTER NOW",
    isGreenMode = false
}: OwnProps) {
    if (isRegistrationNotYetOpened) {
        return (
            <CtaBtn className="closed" >
                Registration opens soon
            </CtaBtn>
        )
    } else if (isRegisrtarionClosed) {
        return (
            <CtaBtn className="closed" >
                Registration closed
            </CtaBtn>
        )
    } else if (!isLoading && isRegistered) {
        return (
            <CtaBtn className="completed" >
                Pre-Registered
            </CtaBtn>
        )
    } else if (!isLoading && !isRegistered) {
        return (
            <CtaBtn
                whiteMode={!isGreenMode}
                onClick={() => onRegister()}
            >
                {registerBtnText}
            </CtaBtn>
        )
    } else {
        return null
    }
}

interface CTAButtonAgencyFlowProps {
    onRegister: () => void;
    isPending: boolean;
    isRegistered: boolean;
}

export function CTAButtonAgencyFlow({ isPending, onRegister, isRegistered }: CTAButtonAgencyFlowProps) {
    if (!isPending && !isRegistered) {
        return (
            <CtaBtn 
                className="register-interest"
                whiteMode={true}
                onClick={() => onRegister()}
            >
                Register interest
            </CtaBtn>
        )
    } else if (isPending) {
        return (
            <CtaBtn className="closed">
                Registration Pending
            </CtaBtn>
        )
    } else if (isRegistered) {
        return (
            <CtaBtn className="completed">
                Registration confirmed
            </CtaBtn>
        )
    } else {
        return null
    }
}

const CtaBtn = styled.button`
    min-width: 250px;
    height: auto;
    padding: 9px 20px !important;
    margin: 0;
    font-size: 16px;
    line-height: 22px;
    font-weight: bold;
    border-radius: 5px;
    border-color: transparent;
    background-color: #0E9655;
    color: #ffffff;
    letter-spacing: 1px !important;
    text-align: center;
    transition: all 100ms ease-in-out;
    ${(props) => props.whiteMode && css`
        background-color: white;
        color: #0E9655;
    `}

    &:hover {
        background-color: #0d8a4d;
        ${(props) => props.whiteMode && css`
            background-color:  #f7f7f7;
        `}
    }
    &.register-interest {
        font-size: 12px;
        line-height: 14px;
        border-radius: 12px;
        text-transform: uppercase;
        padding-top: 7px !important;
        padding-bottom: 7px !important; 
        font-weight: 700;
        min-width: 168px;
        letter-spacing: initial !important;
    }

    &.completed {
        border-color: transparent;
        background-color: white;
        color: black;
        pointer-events: none;
    }
    &.closed {
        border-color: transparent;
        background-color: white;
        color: black;
        pointer-events: none;
        box-shadow: none;
    }
`
const CtaBtn2 = styled(Button)`
    min-width: 250px;
    padding: 10px 55px;
    margin: 0;
    line-height: 22px;
    border-radius: 5px;
    letter-spacing: 1px !important;
    text-align: center;
    font-size: 16px;
    color: #fff;
    background-color: #0E9655;
    font-weight: 600;
    height: auto;
    transition: all 100ms ease-in-out;

    &:hover {
        background-color: #0d8a4d;
        ${(props) => props.whiteMode && css`
            background-color:  #f7f7f7;
        `}
    }

`