import { createElement } from 'react'
import { EventInfoModel } from 'app/events/components/authorized-event-page/events-map/models';
import ClubComponent from './club-root/events.switch'
import AgencyComponent from './agency-root/events.switch'
// import SpeakerCard from './speaker-card/speaker-card-box';
import HprContent from './hpr/hpr-content';
import HprTitle from './hpr/hpr-title';

const title = 'TransferRoom Summit İstanbul';

const eventSettings: EventInfoModel = {
    shortTitle: "İstanbul Summit",
    title: title,
    shortEventDates: "11-12th November 2024",
    eventDates: "11-12th November 2024",
    location: "İstanbul, Turkey",
    componentForClubs: ClubComponent,
    componentForAgents: AgencyComponent,
    speakerCard: () => null,
    countOfEvents: 1,
    agencyCountOfEvents: 1,
    timeZone: 'CET',
    hprInfo: {
        showForClub: false,
        showForAgency: false,
        title: createElement(HprTitle, { title }),
        userActivityPageName: "Home [TransferRoom Summit İstanbul]",
        description: createElement(HprContent),
    },
    registration: {
        activateAgencyFeature: true,
        hasDateSelectorStep: true,
    },
    visibility: {
        isEventFinished: false,
        canShowAttendeesComponent: true,
        shouldShowConfirmedAttendees: true,
        shouldShowConfirmedAttendeesForAgencies: true,
        canShowAgendaBuilder: false,
    }
};

export default eventSettings;