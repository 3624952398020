import { AppState } from 'root.reducer';
import historyAccessor from 'history-accessor';
import { AUTH_LOGIN } from 'store/actionTypes';
import { userPaths } from 'routes/paths';
import { MultiAccountAccessService } from 'api/club/multi-account-access/multi-account-access.service';
import {StaffAccountService} from "../../../api/staff/staff-account/staff-account.service";


class Actions {
    public static loginSelectedClub(clubId: number) {
        return async (dispatch, getState: () => AppState) => {
            try {
                const request = {
                    squadId: clubId
                }
                const data = await MultiAccountAccessService.changeSquad(request);
                
                if (data.isSuccess) {
                    await dispatch({ type: AUTH_LOGIN.SUCCESS, payload: { data: data.auth } });
                    historyAccessor.push('/');
                }
                
            } catch (e) {
                console.error(e)
            } finally {
            
            }
        }
    }

    public static onSwitchAccount() {
        return async (dispatch, getState: () => AppState) => {
            try {
                const data = await StaffAccountService.switchAccount();

                if (data.isSuccess) {
                    await dispatch({ type: AUTH_LOGIN.SUCCESS, payload: { data: data.auth } });
                    historyAccessor.push('/');
                } else if (!data.isEmailConfirmed) {
                    historyAccessor.push(userPaths.personalEmailVerification);
                }

            } catch (e) {
                console.error(e)
            } finally {

            }
        }
    }

    public static onClaimYourProfileClick() {
        return async (dispatch, getState: () => AppState) => {
            historyAccessor.push(userPaths.claimYourProfile);
        }
    }

    public static openSettings() {
        return () => {
            historyAccessor.push(userPaths.editProfileProfile)
        }
    }
}

export {
    Actions as Actions,
};